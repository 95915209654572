import GATSBY_COMPILED_MDX from "/opt/build/repo/content/posts/hello-world.md";
import * as React from "react";
import {graphql} from "gatsby";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import {useTina} from 'tinacms/dist/react';
import {TinaMarkdown} from "tinacms/dist/rich-text";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
const BlogPostPage = ({data, children}) => {
  const {blog} = data;
  const _input = {
    post: {
      title: blog.content.meta.title,
      body: null,
      _sys: {
        filename: `${blog.slug}.md`
      },
      id: blog.content.id
    }
  };
  const {data: tinaData} = useTina({
    query: `query BlogPostQuery($relativePath: String!) {
        post(relativePath: $relativePath) {
          title
          id
          body
          hero
          _sys 
          {
            filename
          }
          
        }
      }`,
    variables: {
      relativePath: `${blog.slug}.md`
    },
    data: _input
  });
  return React.createElement(Layout, {
    title: tinaData.post.title,
    canonical: "https://gatsby-ninja.com/blog/" + blog.slug,
    breadcrumbs: [{
      url: "/",
      name: "Home"
    }, {
      url: "/blog",
      name: "Blog"
    }, {
      url: "/blog/" + blog.slug,
      name: blog.title
    }],
    breadcrumbsJustMeta: true,
    className: "main-blog"
  }, React.createElement(Seo, {
    title: `${blog.content.meta.title} | Blog | Gatsby Ninja`,
    description: blog.excerpt,
    canonical: `https://gatsbyninja.com/blog/${blog.slug}`,
    image: `https://gatsbyninja.com/hero-image.png`
  }), React.createElement("div", {
    className: "px-6 py-16 max-container blog"
  }, React.createElement("h1", null, tinaData.post.title), tinaData?.post?.body ? React.createElement(React.Fragment, null, tinaData.post.hero && React.createElement("img", {
    src: tinaData.post.hero,
    alt: tinaData.title
  }), React.createElement(TinaMarkdown, {
    content: tinaData.post.body
  })) : React.createElement(React.Fragment, null, React.createElement(GatsbyImage, {
    alt: blog.content.meta.title,
    image: getImage(blog.content.hero)
  }), children)));
};
export const query = graphql`
    query BlogPosts($id:String) {
        blog: file(
            childMdx: {id: {eq: $id}}
        ) {
            slug: name
            content: childMdx {
                id
                hero: heroImage {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                meta: frontmatter {
                    title
                }
                excerpt
            }
        }
    }
`;
BlogPostPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPostPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
