/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Welcome to our blog! "), "\n", React.createElement(_components.p, null, "We are excited to share our knowledge and experience in website speed optimization, SEO, core web vitals, and other related topics. "), "\n", React.createElement(_components.p, null, "Our goal is to provide practical tips and strategies to help you improve your website's performance and user experience. "), "\n", React.createElement(_components.p, null, "Whether you're a beginner or an experienced web developer, or a business owner, we hope you find our content informative and useful. "), "\n", React.createElement(_components.p, null, "Stay tuned for more updates and feel free to reach out with any questions or feedback. Let's make the web faster and more efficient together!"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
